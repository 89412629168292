import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect = () => {
    this.site_select = this.element.querySelector("#site-select");
    this.account_type = this.element.querySelector("#account_type");
    this.so = this.element.getAttribute('data-so');
    this.fetch_so_sites(this.so);
    this.fill_so_id();
    this.site_select.style.display = "none";
    this.isEditMode = document.querySelector("form.edit_account") !== null
    if (!this.isEditMode) {
      this.account_type.addEventListener("change", (event) => {
        this.toggle_sites(event);
      });
    }
  }

  toggle_sites = (event) => {
    if (event.target.value === "site_owner_admin") {
      this.site_select.style.display = "none";
    } else {
      this.site_select.style.display = "block";
    }
  }
  

  fill_so_id = () => {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "account[site_owner_id]";
    hiddenField.value = this.so;
    this.element.appendChild(hiddenField);
  }


  fetch_so_sites = (choice) => {
    fetch(`/charge_network/site_owners/${choice}/sites.json`)
      .then(response => response.json())
      .then(data => {
        this.generate_site_options(data)
      })
  }

  generate_site_options = (data) => {
    this.site_select.innerHTML = "";
    data.sites.forEach(site => {
      if (!this.site_select.querySelector(".checkbox-list-label")) {
        const topLabel = document.createElement("div");
        topLabel.className = "text-sm font-medium text-gray-700";
        topLabel.classList.add("checkbox-list-label");
        topLabel.textContent = "Accès";
        topLabel.style.fontWeight = "bold";
        topLabel.style.marginBottom = "10px";
        this.site_select.appendChild(topLabel);
      }

      const container = document.createElement("div");
      container.style.display = "flex";
      container.style.marginBottom = "10px";

      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.id = `site_${site[0].id}`;
      checkbox.name = "sites[]";
      checkbox.value = site[0].id;
      checkbox.style.marginRight = "10px";
      checkbox.style.marginTop = "2px";

      const label = document.createElement("label");
      label.htmlFor = `site_${site[0].id}`;
      label.textContent = site[0].name;

      container.appendChild(checkbox);
      container.appendChild(label);
      this.site_select.appendChild(container);
    });
  }

}
